import {h} from 'preact'

export default function ChartIcon(props) {
  return (
    <svg width={40} height={40} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#D16EA7"
        strokeWidth={3}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M38 10v20a8 8 0 01-8 8H10a8 8 0 01-8-8V10a8 8 0 018-8h20a8 8 0 018 8z" />
        <path d="M16.666 28.334v-6.666C16.666 20.746 15.92 20 15 20h-3.334c-.92 0-1.666.746-1.666 1.666v6.454m13.334.214v-15c0-.92-.746-1.666-1.666-1.666h-3.334c-.92 0-1.666.746-1.666 1.666v15M30 28.122V17.5c0-.92-.746-1.666-1.666-1.666H25c-.92 0-1.666.746-1.666 1.666v10.834M30 28.34H10" />
      </g>
    </svg>
  )
}
