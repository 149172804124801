import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './JusthatchedFeatures.scss'
import justhatchedScreensImg from '../../assets/images/justhatched/justhatched-screens.png'
import justhatchedScreensImg2x from '../../assets/images/justhatched/justhatched-screens@2x.png'
import justhatchedScreensSmallImg from '../../assets/images/justhatched/justhatched-screens_small.png'
import justhatchedScreensSmallImg2x from '../../assets/images/justhatched/justhatched-screens_small@2x.png'
import justhatchedSecondScreensImg from '../../assets/images/justhatched/justhatched-second-screens.png'
import justhatchedSecondScreensImg2x from '../../assets/images/justhatched/justhatched-second-screens@2x.png'
import justhatchedSecondScreensSmallImg from '../../assets/images/justhatched/justhatched-second-screens_small.png'
import justhatchedSecondScreensSmallImg2x from '../../assets/images/justhatched/justhatched-second-screens_small@2x.png'
import justhatchedThirdScreensImg from '../../assets/images/justhatched/justhatched-third-screens.png'
import justhatchedThirdScreensImg2x from '../../assets/images/justhatched/justhatched-third-screens@2x.png'
import justhatchedThirdScreensSmallImg from '../../assets/images/justhatched/justhatched-third-screens_small.png'
import justhatchedThirdScreensSmallImg2x from '../../assets/images/justhatched/justhatched-third-screens_small@2x.png'
import {Container, Link} from '../common'
import {QUERY_BREAKPOINT, BRAND} from '../../constants'

const imageSize = {
  small: {
    width: 441,
    height: 488,
  },
  normal: {
    width: 641,
    height: 709,
  },
}

export default function JusthatchedFeatures() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const firstImageRef = useRef(null)
  const firstFeatureRef = useRef(null)
  const secondImageRef = useRef(null)
  const secondFeatureRef = useRef(null)
  const thirdImageRef = useRef(null)
  const linkRef = useRef(null)

  useEffect(() => {
    const imageElements = [
      firstImageRef.current,
      secondImageRef.current,
      thirdImageRef.current,
    ]

    if (isSm) {
      imageElements.forEach((image) =>
        gsap.from(image, {
          scrollTrigger: {
            start: 'top bottom',
            trigger: image,
            scrub: 0.5,
          },
          duration: 1.2,
          opacity: 0,
          y: 100,
        })
      )
      gsap.from(firstFeatureRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: firstFeatureRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        y: 50,
      })
      gsap.from(secondFeatureRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: secondFeatureRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        y: 50,
      })
      gsap.from(linkRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: linkRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        scale: 0.9,
      })
      return
    }

    imageElements.forEach((image) =>
      gsap.from(image, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: image,
          scrub: 0.5,
        },
        duration: 1.2,
        opacity: 0,
        y: 100,
      })
    )
    gsap.from(firstFeatureRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: firstFeatureRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      x: 50,
    })
    gsap.from(secondFeatureRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: secondFeatureRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      x: 50,
    })
    gsap.from(linkRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: linkRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      scale: 0.9,
    })
  }, [])

  return (
    <section class="JusthatchedFeatures">
      <Container type="small" className="FeaturesContainer">
        <div class="JusthatchedFeatures-Block">
          <picture>
            <source
              {...imageSize.small}
              media={QUERY_BREAKPOINT.sm}
              srcSet={`${justhatchedScreensSmallImg}, ${justhatchedScreensSmallImg2x} 2x`}
            />
            <img
              {...imageSize.normal}
              ref={firstImageRef}
              class="JusthatchedFeatures-Image"
              src={justhatchedScreensImg}
              srcSet={`${justhatchedScreensImg2x} 2x`}
              alt={`${BRAND.justhatched.title} screens`}
            />
          </picture>
          <div ref={firstFeatureRef}>
            <h3 class="Heading Heading_feature">
              Prized
              <br />
              Design
            </h3>
            <p class="FeatureText">
              Designed and developed by
              <br />a design award-winning agency.
            </p>
          </div>
        </div>
        <div class="JusthatchedFeatures-Block">
          <picture>
            <source
              {...imageSize.small}
              media={QUERY_BREAKPOINT.sm}
              srcSet={`${justhatchedSecondScreensSmallImg}, ${justhatchedSecondScreensSmallImg2x} 2x`}
            />
            <img
              {...imageSize.normal}
              ref={secondImageRef}
              class="JusthatchedFeatures-Image"
              src={justhatchedSecondScreensImg}
              srcSet={`${justhatchedSecondScreensImg2x} 2x`}
              alt={`${BRAND.justhatched.title} second screens`}
            />
          </picture>
          <div ref={secondFeatureRef}>
            <h3 class="Heading Heading_feature">Hifidelic UX</h3>
            <p class="JusthatchedFeatures-Text FeatureText">
              An extensive and complexly built application containing more than
              150 screens. Thoughtful UX depicts all user scenarios and
              instances based on the baby's growth. Statistics and dynamic
              graphics are meticulously crafted.
            </p>
          </div>
        </div>
        <div class="JusthatchedFeatures-Block">
          <picture>
            <source
              {...imageSize.small}
              media={QUERY_BREAKPOINT.sm}
              srcSet={`${justhatchedThirdScreensSmallImg}, ${justhatchedThirdScreensSmallImg2x} 2x`}
            />
            <img
              {...imageSize.normal}
              ref={thirdImageRef}
              class="JusthatchedFeatures-Image"
              src={justhatchedThirdScreensImg}
              srcSet={`${justhatchedThirdScreensImg2x} 2x`}
              alt={`${BRAND.justhatched.title} third screens`}
            />
          </picture>
          <div ref={linkRef} class="JusthatchedFeatures-Wrapper">
            <Link isBlank class="Button" href={BRAND.justhatched.website}>
              View website
            </Link>
          </div>
        </div>
      </Container>
    </section>
  )
}
