import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import PropTypes from 'prop-types'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './BrandBanner.scss'
import {QUERY_BREAKPOINT, BRAND} from '../../constants'

BrandBanner.propTypes = {
  type: PropTypes.oneOf(['strongher', 'justhatched', 'madfit', 'stumbler'])
    .isRequired,
}

export default function BrandBanner({type}) {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const sectionRef = useRef(null)
  const logoRef = useRef(null)

  useEffect(() => {
    gsap.to(logoRef.current, {
      scrollTrigger: {
        scrub: 0.5,
        start: 'center center',
        trigger: sectionRef.current,
      },
      y: -200,
      scale: 0.9,
      opacity: 0,
    })
  }, [])

  const brand = BRAND[type]

  const [width, height] = brand.imageSize

  return (
    <section ref={sectionRef} class={`BrandBanner BrandBanner_${type}`}>
      <img
        ref={logoRef}
        class="BrandBanner-Image"
        width={isSm ? width * 0.8 : width}
        height={isSm ? height * 0.8 : height}
        src={brand.image}
        alt={brand.title}
      />
    </section>
  )
}
