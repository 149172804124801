import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import Router, {route} from 'preact-router'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'

import './App.scss'
import {BrandBanner} from './common'
import {
  Header,
  Footer,
  Features,
  Stats,
  Services,
  CaseStudies,
  Contact,
  Team,
} from './base'
import {StrongherBanner, StrongherAbout, StrongherFeatures} from './strongher'
import {
  JusthatchedBanner,
  JusthatchedAbout,
  JusthatchedFeatures,
} from './justhatched'
import {MadfitBanner, MadfitAbout, MadfitFeatures} from './madfit'
import {StumblerBanner, StumblerAbout, StumblerFeatures} from './stumbler'
import {QUERY_BREAKPOINT} from '../constants'

const killScrollTriggers = (scrollTriggers) => {
  scrollTriggers.forEach((trigger) => trigger.kill())
}

const Redirect = (props) => {
  useEffect(() => {
    route(props.to, true)
  }, [])

  return null
}

const Main = () => {
  const isMounted = useRef(false)
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})

  useEffect(() => {
    if (isMounted.current) {
      window.location.reload()
    } else {
      isMounted.current = true
    }
    return () => {
      const scrollTriggers = ScrollTrigger.getAll()
      killScrollTriggers(scrollTriggers)
    }
  }, [isSm])

  return (
    <div class="FadeIn FadeIn_longer">
      <Header />
      <main>
        <Features />
        <Stats />
        <Services />
        <CaseStudies />
        <BrandBanner type="strongher" />
        <StrongherBanner />
        <StrongherAbout />
        <StrongherFeatures />
        <BrandBanner type="madfit" />
        <MadfitBanner />
        <MadfitAbout />
        <MadfitFeatures />
        <BrandBanner type="justhatched" />
        <JusthatchedBanner />
        <JusthatchedAbout />
        <JusthatchedFeatures />
        <BrandBanner type="stumbler" />
        <StumblerBanner />
        <StumblerAbout />
        <StumblerFeatures />
        <Contact />
      </main>
      <Footer />
    </div>
  )
}

export default function App() {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

  return (
    <div class="App">
      <Router>
        <Main path="/" />
        <Team path="/team" />
        <Redirect default to="/" />
      </Router>
    </div>
  )
}
