import {h} from 'preact'
import PropTypes from 'prop-types'

Link.propTypes = {
  isBlank: PropTypes.bool,
  children: PropTypes.string.isRequired,
}

export default function Link({isBlank, children, ...props}) {
  return (
    <a
      target={isBlank ?? '_blank'}
      rel={isBlank ?? 'noopener noreferrer'}
      {...props}
    >
      {children}
    </a>
  )
}
