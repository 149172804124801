import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './StrongherAbout.scss'
import strongherDevicesImg from '../../assets/images/strongher/strongher-devices.png'
import strongherDevicesImg2x from '../../assets/images/strongher/strongher-devices@2x.png'
import strongherDevicesTabletImg from '../../assets/images/strongher/strongher-devices_tablet.png'
import strongherDevicesTabletImg2x from '../../assets/images/strongher/strongher-devices_tablet@2x.png'
import strongherDevicesMobileImg from '../../assets/images/strongher/strongher-devices_mobile.png'
import strongherDevicesMobileImg2x from '../../assets/images/strongher/strongher-devices_mobile@2x.png'
import {Container, BrandDescription} from '../common'
import {QUERY_BREAKPOINT, BRAND} from '../../constants'

export default function StrongherAbout() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const imageRef = useRef(null)
  const descriptionRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.from(imageRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: imageRef.current,
          scrub: 0.5,
        },
        scale: 1.5,
        opacity: 0.2,
      })
      gsap.from(descriptionRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: descriptionRef.current,
          scrub: 0.5,
        },
        y: 50,
        opacity: 0,
      })
      return
    }

    gsap.from(imageRef.current, {
      scrollTrigger: {
        start: 'center bottom',
        trigger: imageRef.current,
        scrub: 0.5,
      },
      opacity: 0.2,
    })
    gsap.from(descriptionRef.current, {
      scrollTrigger: {
        start: 'center bottom',
        trigger: descriptionRef.current,
        scrub: 0.5,
      },
      y: 50,
      opacity: 0,
    })
  }, [])

  return (
    <section class="StrongherAbout">
      <Container type="grid" contentClass="AboutContent">
        <picture>
          <source
            width={599}
            height={304}
            media={QUERY_BREAKPOINT.xs}
            srcSet={`${strongherDevicesMobileImg}, ${strongherDevicesMobileImg2x} 2x`}
          />
          <source
            width={858}
            height={435}
            media={QUERY_BREAKPOINT.md}
            srcSet={`${strongherDevicesTabletImg}, ${strongherDevicesTabletImg2x} 2x`}
          />
          <img
            ref={imageRef}
            class="StrongherAbout-Image"
            width={1260}
            height={637}
            src={strongherDevicesImg}
            srcSet={`${strongherDevicesImg2x} 2x`}
            alt={`${BRAND.strongher.title} examples`}
          />
        </picture>
        <div ref={descriptionRef}>
          <BrandDescription type="strongher" />
        </div>
      </Container>
    </section>
  )
}
