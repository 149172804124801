import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './JusthatchedAbout.scss'
import justhatchedDevicesImg from '../../assets/images/justhatched/justhatched-devices.png'
import justhatchedDevicesImg2x from '../../assets/images/justhatched/justhatched-devices@2x.png'
import justhatchedDevicesTabletImg from '../../assets/images/justhatched/justhatched-devices_tablet.png'
import justhatchedDevicesTabletImg2x from '../../assets/images/justhatched/justhatched-devices_tablet@2x.png'
import justhatchedDevicesMobileImg from '../../assets/images/justhatched/justhatched-devices_mobile.png'
import justhatchedDevicesMobileImg2x from '../../assets/images/justhatched/justhatched-devices_mobile@2x.png'
import {Container, BrandDescription} from '../common'
import {QUERY_BREAKPOINT, BRAND} from '../../constants'

export default function JusthatchedAbout() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const imageRef = useRef(null)
  const descriptionRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.from(imageRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: imageRef.current,
          scrub: 0.5,
        },
        scale: 1.5,
        opacity: 0.2,
      })
      gsap.from(descriptionRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: descriptionRef.current,
          scrub: 0.5,
        },
        y: 50,
        opacity: 0,
      })
      return
    }

    gsap.from(imageRef.current, {
      scrollTrigger: {
        start: 'center bottom',
        trigger: imageRef.current,
        scrub: 0.5,
      },
      opacity: 0.2,
    })
    gsap.from(descriptionRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: descriptionRef.current,
        scrub: 0.5,
      },
      y: 50,
      opacity: 0,
    })
  }, [])

  return (
    <section class="JusthatchedAbout">
      <Container type="grid" contentClass="AboutContent">
        <picture>
          <source
            height={221}
            width={429}
            media={QUERY_BREAKPOINT.xs}
            srcSet={`${justhatchedDevicesMobileImg}, ${justhatchedDevicesMobileImg2x} 2x`}
          />
          <source
            height={395}
            width={767}
            media={QUERY_BREAKPOINT.md}
            srcSet={`${justhatchedDevicesTabletImg}, ${justhatchedDevicesTabletImg2x} 2x`}
          />
          <img
            ref={imageRef}
            class="JusthatchedAbout-Image"
            height={579}
            width={1125}
            src={justhatchedDevicesImg}
            srcSet={`${justhatchedDevicesImg2x} 2x`}
            alt={`${BRAND.justhatched.title} examples`}
          />
        </picture>
        <div ref={descriptionRef}>
          <BrandDescription type="justhatched" />
        </div>
      </Container>
    </section>
  )
}
