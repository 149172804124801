import {h} from 'preact'
import PropTypes from 'prop-types'
import cn from 'clsx'

import './Logo.scss'
import logoSvg from '../../assets/images/logos/logo.svg'

Logo.propTypes = {
  isFooter: PropTypes.bool,
}

export default function Logo({isFooter}) {
  const Title = isFooter ? 'p' : 'h1'

  return (
    <div class={cn('Logo', isFooter && 'Logo_footer')}>
      <img
        width={isFooter ? 80 : 21}
        height={isFooter ? 30 : 8}
        src={logoSvg}
        alt="Vibe Media"
      />
      <Title class="Logo-Title">
        Vibe <span class="Logo-Word">Media</span>
      </Title>
    </div>
  )
}
