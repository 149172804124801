if (process.env.NODE_ENV === 'development') {
  require('preact/debug')
}

import {h, render} from 'preact'
import gsap from 'gsap'

import './styles/index.scss'

gsap.defaults({
  ease: 'power4',
  duration: 1,
})

const handleShowApp = () => {
  const preloader = document.getElementById('preloader')
  preloader.classList.remove('Preloader_show')
  document.body.style.overflow = 'auto'
  setTimeout(() => preloader.remove(), 1000)
}

function init() {
  document.addEventListener('DOMContentLoaded', handleShowApp)
  const App = require('./components/App').default
  render(<App />, document.getElementById('app'))
}

if (module.hot) {
  module.hot.accept('./components/App', init)
}

init()
