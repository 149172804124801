import {h} from 'preact'
import PropTypes from 'prop-types'

import './BrandDescription.scss'
import {Link} from '.'
import {BRAND} from '../../constants'

BrandDescription.propTypes = {
  type: PropTypes.oneOf(['strongher', 'justhatched', 'madfit', 'stumbler'])
    .isRequired,
}

export default function BrandDescription({type}) {
  const brand = BRAND[type]

  return (
    <div class={`BrandDescription BrandDescription_${type}`}>
      <img
        class="BrandDescription-Image"
        width={92}
        height={92}
        src={brand.icon[0]}
        srcSet={`${brand.icon[1]} 2x`}
        alt={brand.title}
      />
      <h2 class="BrandDescription-Title">{brand.title}</h2>
      <p class="BrandDescription-Text">{brand.text}</p>
      <Link class="BrandDescription-Link" isBlank href={brand.url}>
        AppStore →
      </Link>
    </div>
  )
}
