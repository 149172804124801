import {h, Fragment} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './StrongherBanner.scss'
import {Container} from '../common'
import {QUERY_BREAKPOINT} from '../../constants'

export default function StrongherBanner() {
  const isXs = useMediaQuery({query: QUERY_BREAKPOINT.xs})
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const titleRef = useRef(null)
  const textRef = useRef(null)
  const decorationRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.from(titleRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: titleRef.current,
          scrub: 0.5,
        },
        x: 50,
      })
      gsap.from(textRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: textRef.current,
          scrub: 0.5,
        },
        x: -30,
      })
      gsap.from(decorationRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: decorationRef.current,
          scrub: 0.5,
        },
        x: 100,
        scale: 0.9,
        opacity: 0,
        duration: 4,
      })
      return
    }

    gsap.from(titleRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: titleRef.current,
        scrub: 0.5,
      },
      x: -50,
    })
    gsap.from(textRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: textRef.current,
        scrub: 0.5,
      },
      x: 50,
    })
    gsap.from(decorationRef.current, {
      scrollTrigger: {
        start: 'top center',
        trigger: decorationRef.current,
        scrub: 0.5,
      },
      x: 100,
      scale: 0.9,
      opacity: 0,
      duration: 4,
    })
  }, [])

  return (
    <section class="StrongherBanner">
      <Container type="grid" contentClass="BannerContent">
        <div>
          <h2 ref={titleRef} class="Heading">
            {isXs ? (
              <>
                Gym
                <br />
                & home
                <br />
                workouts
              </>
            ) : (
              <>
                Gym & home
                <br />
                workouts
              </>
            )}
          </h2>
          <p ref={textRef} class="Subheading">
            by Melissa Chalmers
          </p>
        </div>
        <div ref={decorationRef} class="StrongherBanner-Decoration" />
      </Container>
    </section>
  )
}
