import {h} from 'preact'
import {useState, useEffect, useRef} from 'preact/hooks'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import gsap from 'gsap'

import './Contact.scss'
import {Container} from '../common'

const Loader = () => (
  <div class="FadeIn">
    <svg class="Contact-Progress" width="30" height="29" viewBox="0 0 30 29">
      <g
        transform="translate(2 2)"
        stroke-width="4"
        fill="none"
        fill-rule="evenodd"
      >
        <circle
          stroke="currentColor"
          opacity=".3"
          cx="12.77"
          cy="12.5"
          r="12.5"
        />
        <path
          d="M23.72 6.516A12.497 12.497 0 0 0 12.744 0a12.497 12.497 0 0 0-10.95 6.467"
          stroke="currentColor"
          stroke-linecap="round"
        />
      </g>
    </svg>
  </div>
)

export default function Contact() {
  const [values, setValues] = useState({})
  const [loading, setLoading] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    gsap.from(textRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: textRef.current,
        scrub: 0.5,
      },
      y: -30,
      opacity: 0,
    })
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      await axios.post(
        'https://api.strongherapp.com/api/mail/contact-us',
        values
      )
      toast.success('The message has been sent! 👌')
      setValues({})
    } catch (error) {
      toast.error('Something went wrong... 😬 ')
    } finally {
      setLoading(false)
    }
  }

  const handleChange = ({target}) =>
    setValues({...values, [target.name]: target.value})

  return (
    <section id="contact" className="Contact">
      <Container contentClass="Contact-Content">
        <h2 class="Title">
          Interested in
          <br />
          working with us?
        </h2>
        <p ref={textRef} class="Contact-Text">
          Get in touch below ↓
        </p>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <div class="Contact-Grid">
              <label class="Contact-Label">
                <input
                  required
                  name="name"
                  value={values.name ?? ''}
                  type="text"
                  placeholder="Name"
                  onInput={handleChange}
                />
              </label>
              <label class="Contact-Label">
                <input
                  required
                  name="email"
                  value={values.email ?? ''}
                  type="email"
                  placeholder="Email"
                  onInput={handleChange}
                />
              </label>
              <label class="Contact-Label">
                <input
                  required
                  name="username"
                  value={values.username ?? ''}
                  placeholder="Social media handle"
                  onInput={handleChange}
                />
              </label>
              <label class="Contact-Label">
                <input
                  required
                  name="followers"
                  value={values.followers ?? ''}
                  type="number"
                  placeholder="Number of subscribers"
                  onInput={handleChange}
                />
              </label>
            </div>
            <label class="Contact-Label Contact-Label_textarea">
              <textarea
                required
                name="message"
                value={values.message ?? ''}
                rows={4}
                placeholder="Message"
                onInput={handleChange}
              />
            </label>
          </fieldset>
          <button
            class="Contact-Button Button"
            type="submit"
            disabled={loading}
          >
            {loading ? (
              <Loader />
            ) : (
              <span class="FadeIn">Send your message</span>
            )}
          </button>
        </form>
      </Container>
      <ToastContainer />
    </section>
  )
}
