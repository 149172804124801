import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

import './StrongherFeatures.scss'
import strongherScreensImg from '../../assets/images/strongher/strongher-screens.png'
import strongherScreensImg2x from '../../assets/images/strongher/strongher-screens@2x.png'
import strongherScreensSmallImg from '../../assets/images/strongher/strongher-screens_small.png'
import strongherScreensSmallImg2x from '../../assets/images/strongher/strongher-screens_small@2x.png'
import strongherSecondScreensImg from '../../assets/images/strongher/strongher-second-screens.png'
import strongherSecondScreensImg2x from '../../assets/images/strongher/strongher-second-screens@2x.png'
import strongherSecondScreensSmallImg from '../../assets/images/strongher/strongher-second-screens_small.png'
import strongherSecondScreensSmallImg2x from '../../assets/images/strongher/strongher-second-screens_small@2x.png'
import {Container, Link} from '../common'
import {QUERY_BREAKPOINT, BREAKPOINT, BRAND} from '../../constants'

export default function StrongherFeatures() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const blockRef = useRef(null)
  const firstImageRef = useRef(null)
  const firstFeatureRef = useRef(null)
  const secondImageRef = useRef(null)
  const secondFeatureRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.from(firstImageRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: firstImageRef.current,
          scrub: 0.5,
        },
        opacity: 0.7,
        scale: 0.8,
        y: 50,
        filter: 'blur(5px)',
      })
      gsap.from(firstFeatureRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: firstFeatureRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        y: 50,
      })
      gsap.from(secondImageRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: secondImageRef.current,
          scrub: 0.5,
        },
        opacity: 0.7,
        scale: 0.8,
        y: 50,
        filter: 'blur(5px)',
      })
      gsap.from(secondFeatureRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: secondFeatureRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        y: 50,
      })
      return
    }

    ScrollTrigger.matchMedia({
      [QUERY_BREAKPOINT.lg]: () => {
        const tl = gsap.timeline({
          scrollTrigger: {
            scrub: 0.5,
            start: 'center center',
            end: '+=1000',
            trigger: blockRef.current,
            pin: true,
          },
        })
        tl.set(firstImageRef.current, {scale: 2.2, x: -300})
          .to(firstImageRef.current, {
            scale: 1,
            x: 0,
            opacity: 0.7,
            filter: 'blur(5px)',
          })
          .from(firstFeatureRef.current, {
            opacity: 0,
            x: 100,
          })
        gsap.from(secondImageRef.current, {
          scrollTrigger: {
            start: 'center bottom',
            trigger: secondImageRef.current,
            scrub: 0.5,
          },
          filter: 'blur(10px)',
          x: -100,
          opacity: 0.7,
          scale: 0.9,
          duration: 2,
        })
        gsap.from(secondFeatureRef.current, {
          scrollTrigger: {
            start: 'bottom bottom',
            trigger: secondFeatureRef.current,
            scrub: 0.5,
          },
          opacity: 0,
          x: -50,
        })
      },
      [`(min-width: ${BREAKPOINT.sm}px) and (max-width: ${
        BREAKPOINT.lg - 1
      }px)`]: () => {
        gsap.from(firstImageRef.current, {
          scrollTrigger: {
            start: 'center bottom',
            trigger: firstImageRef.current,
            scrub: 0.5,
          },
          opacity: 0.7,
          scale: 0.8,
          y: 50,
          filter: 'blur(5px)',
        })
        gsap.from(firstFeatureRef.current, {
          scrollTrigger: {
            start: 'bottom bottom',
            trigger: firstFeatureRef.current,
            scrub: 0.5,
          },
          opacity: 0,
          y: 50,
        })
        gsap.from(secondImageRef.current, {
          scrollTrigger: {
            start: 'center bottom',
            trigger: secondImageRef.current,
            scrub: 0.5,
          },
          opacity: 0.7,
          scale: 0.8,
          y: 50,
          filter: 'blur(5px)',
        })
        gsap.from(secondFeatureRef.current, {
          scrollTrigger: {
            start: 'center bottom',
            trigger: secondFeatureRef.current,
            scrub: 0.5,
          },
          opacity: 0,
          y: 50,
        })
      },
    })
  }, [])

  return (
    <section class="StrongherFeatures">
      <Container className="FeaturesContainer">
        <div>
          <div
            ref={blockRef}
            class="StrongherFeatures-Block StrongherFeatures-Block_first"
          >
            <div ref={firstFeatureRef} class="StrongherFeatures-Wrapper">
              <h3 class="StrongherFeatures-Title Heading Heading_feature">
                1,000,000+ downloads
              </h3>
              <p class="StrongherFeatures-Text FeatureText">
                StrongHer has one of the highest ASO ratings and keyword search
                in the world.
              </p>
            </div>
            <picture>
              <source
                width={960}
                height={640}
                media={QUERY_BREAKPOINT.sm}
                srcSet={`${strongherScreensSmallImg}, ${strongherScreensSmallImg2x} 2x`}
              />
              <img
                ref={firstImageRef}
                class="StrongherFeatures-FirstImage"
                width={1326}
                height={884}
                src={strongherScreensImg}
                srcSet={`${strongherScreensImg2x} 2x`}
                alt={`${BRAND.strongher.title} screens`}
              />
            </picture>
          </div>
          <div class="StrongherFeatures-Block StrongherFeatures-Block_second">
            <picture>
              <source
                width={769}
                height={474}
                media={QUERY_BREAKPOINT.sm}
                srcSet={`${strongherSecondScreensSmallImg}, ${strongherSecondScreensSmallImg2x} 2x`}
              />
              <img
                ref={secondImageRef}
                class="StrongherFeatures-SecondImage"
                width={1058}
                height={653}
                src={strongherSecondScreensImg}
                srcSet={`${strongherSecondScreensImg2x} 2x`}
                alt={`${BRAND.strongher.title} second screens`}
              />
            </picture>
            <div ref={secondFeatureRef}>
              <h3 class="StrongherFeatures-Title Heading Heading_feature">
                Strong Brand Presence
              </h3>
              <p class="StrongherFeatures-Text FeatureText">
                Designed with vibrant solid colors and user-friendly flows, we
                kept the female community in mind, designing and implementing an
                energetic user experience.
              </p>
              <Link
                isBlank
                class="StrongherFeatures-Button Button"
                href={BRAND.strongher.website}
              >
                View website
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
