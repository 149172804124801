import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './JusthatchedBanner.scss'
import {Container} from '../common'
import {QUERY_BREAKPOINT} from '../../constants'

export default function JusthatchedBanner() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const titleRef = useRef(null)
  const decorationRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.from(titleRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: titleRef.current,
          scrub: 0.5,
        },
        x: 50,
      })
      gsap.from(decorationRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: decorationRef.current,
          scrub: 0.5,
        },
        y: 100,
        opacity: 0,
        scale: 0.8,
        duration: 2.5,
      })
      return
    }

    gsap.from(titleRef.current, {
      scrollTrigger: {
        start: 'center bottom',
        trigger: titleRef.current,
        scrub: 0.5,
      },
      y: 100,
    })
    gsap.from(decorationRef.current, {
      scrollTrigger: {
        start: 'top center',
        trigger: decorationRef.current,
        scrub: 0.5,
      },
      y: -100,
      opacity: 0,
      scale: 0.8,
      duration: 2.5,
    })
  }, [])

  return (
    <section class="JusthatchedBanner">
      <Container type="grid" contentClass="BannerContent">
        <div>
          <h2 ref={titleRef} class="JusthatchedBanner-Heading Heading">
            Newborn baby tracker app
          </h2>
        </div>
        <div ref={decorationRef} class="JusthatchedBanner-Decoration" />
      </Container>
    </section>
  )
}
