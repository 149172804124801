import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './StumblerBanner.scss'
import {Container} from '../common'
import {QUERY_BREAKPOINT} from '../../constants'

export default function StumblerBanner() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const decorationRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.to(decorationRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: decorationRef.current,
          scrub: 0.5,
        },
        y: -600,
        scale: 0.8,
        duration: 8,
      })
      return
    }

    gsap.to(decorationRef.current, {
      scrollTrigger: {
        start: 'top bottom',
        trigger: decorationRef.current,
        scrub: 0.5,
      },
      y: -800,
      scale: 0.8,
      duration: 8,
    })
  }, [])

  return (
    <section class="StumblerBanner">
      <Container
        type="grid"
        contentClass="StumblerBanner-Content BannerContent"
      >
        <h2 class="StumblerBanner-Heading Heading">
          The most viral videos and images
        </h2>
        <div ref={decorationRef} class="StumblerBanner-Decoration" />
      </Container>
    </section>
  )
}
