import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './MadfitAbout.scss'
import madfitDevicesImg from '../../assets/images/madfit/madfit-devices.png'
import madfitDevicesImg2x from '../../assets/images/madfit/madfit-devices@2x.png'
import madfitDevicesTabletImg from '../../assets/images/madfit/madfit-devices_tablet.png'
import madfitDevicesTabletImg2x from '../../assets/images/madfit/madfit-devices_tablet@2x.png'
import madfitDevicesMobileImg from '../../assets/images/madfit/madfit-devices_mobile.png'
import madfitDevicesMobileImg2x from '../../assets/images/madfit/madfit-devices_mobile@2x.png'
import {Container, BrandDescription} from '../common'
import {QUERY_BREAKPOINT, BRAND} from '../../constants'

export default function MadfitAbout() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const imageRef = useRef(null)
  const descriptionRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.from(imageRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: imageRef.current,
          scrub: 0.5,
        },
        scale: 1.5,
        opacity: 0.2,
      })
      gsap.from(descriptionRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: descriptionRef.current,
          scrub: 0.5,
        },
        y: 50,
        opacity: 0,
      })
      return
    }

    gsap.from(imageRef.current, {
      scrollTrigger: {
        start: 'center bottom',
        trigger: imageRef.current,
        scrub: 0.5,
      },
      opacity: 0.2,
    })
    gsap.from(descriptionRef.current, {
      scrollTrigger: {
        start: 'center bottom',
        trigger: descriptionRef.current,
        scrub: 0.5,
      },
      y: 50,
      opacity: 0,
    })
  }, [])

  return (
    <section class="MadfitAbout">
      <Container type="grid" contentClass="AboutContent">
        <picture>
          <source
            width={500}
            height={246}
            media={QUERY_BREAKPOINT.xs}
            srcSet={`${madfitDevicesMobileImg}, ${madfitDevicesMobileImg2x} 2x`}
          />
          <source
            width={710}
            height={352}
            media={QUERY_BREAKPOINT.md}
            srcSet={`${madfitDevicesTabletImg}, ${madfitDevicesTabletImg2x} 2x`}
          />
          <img
            ref={imageRef}
            class="MadfitAbout-Image"
            width={1042}
            height={521}
            src={madfitDevicesImg}
            srcSet={`${madfitDevicesImg2x} 2x`}
            alt={`${BRAND.madfit.title} examples`}
          />
        </picture>
        <div ref={descriptionRef}>
          <BrandDescription type="madfit" />
        </div>
      </Container>
    </section>
  )
}
