import {h, Fragment} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './MadfitFeatures.scss'
import madfitScreenImg from '../../assets/images/madfit/madfit-screen.png'
import madfitScreenImg2x from '../../assets/images/madfit/madfit-screen@2x.png'
import madfitScreenSecondImg from '../../assets/images/madfit/madfit-screen-second.png'
import madfitScreenSecondImg2x from '../../assets/images/madfit/madfit-screen-second@2x.png'
import madfitScreenThirdImg from '../../assets/images/madfit/madfit-screen-third.png'
import madfitScreenThirdImg2x from '../../assets/images/madfit/madfit-screen-third@2x.png'
import madfitScreenFourthImg from '../../assets/images/madfit/madfit-screen-fourth.png'
import madfitScreenFourthImg2x from '../../assets/images/madfit/madfit-screen-fourth@2x.png'
import madfitScreenFifthImg from '../../assets/images/madfit/madfit-screen-fifth.png'
import madfitScreenFifthImg2x from '../../assets/images/madfit/madfit-screen-fifth@2x.png'
import madfitScreenSixthImg from '../../assets/images/madfit/madfit-screen-sixth.png'
import madfitScreenSixthImg2x from '../../assets/images/madfit/madfit-screen-sixth@2x.png'
import madfitScreenSeventhImg from '../../assets/images/madfit/madfit-screen-seventh.png'
import madfitScreenSeventhImg2x from '../../assets/images/madfit/madfit-screen-seventh@2x.png'
import {Container, Link} from '../common'
import {QUERY_BREAKPOINT, BRAND} from '../../constants'

const effects = [{y: -30}, {x: -30}, {y: 30}, {x: 30}]

const Image = ({images, order}) => (
  <img
    class="MadfitFeatures-Image"
    width={329}
    height={589}
    src={images[0]}
    srcSet={`${images[1]} 2x`}
    alt={`${BRAND.madfit.title}${order ? ` ${order}` : ''} screen`}
  />
)

export default function MadfitFeatures() {
  const isLg = useMediaQuery({query: '(max-width: 1399px)'})
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const isXs = useMediaQuery({query: QUERY_BREAKPOINT.xs})
  const gridRef = useRef(null)
  const firstFeatureRef = useRef(null)
  const secondFeatureRef = useRef(null)
  const linkRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.utils
        .selector(gridRef.current)('.MadfitFeatures-Image')
        .forEach((image) =>
          gsap.from(image, {
            scrollTrigger: {
              start: 'top bottom',
              trigger: image,
              scrub: 0.5,
            },
            opacity: 0,
            scale: 0.95,
            y: 30,
          })
        )
      gsap.from(firstFeatureRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: firstFeatureRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        y: 50,
      })
      gsap.from(secondFeatureRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: secondFeatureRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        y: 50,
      })
      gsap.from(linkRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: linkRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        scale: 0.9,
      })
      return
    }

    gsap.utils
      .selector(gridRef.current)('.MadfitFeatures-Image')
      .forEach((image) =>
        gsap.from(image, {
          scrollTrigger: {
            start: 'center bottom',
            trigger: image,
            scrub: 0.5,
          },
          opacity: 0,
          scale: 0.95,
          ...effects[Math.round(Math.random() * 3)],
        })
      )
    gsap.from(firstFeatureRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: firstFeatureRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      x: 50,
    })
    gsap.from(secondFeatureRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: secondFeatureRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      y: 50,
    })
    gsap.from(linkRef.current, {
      scrollTrigger: {
        start: 'center bottom',
        trigger: linkRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      scale: 0.9,
    })
  }, [])

  const screens = [
    <Image images={[madfitScreenImg, madfitScreenImg2x]} />,
    <Image
      images={[madfitScreenSecondImg, madfitScreenSecondImg2x]}
      order="second"
    />,
    <Image
      images={[madfitScreenThirdImg, madfitScreenThirdImg2x]}
      order="third"
    />,
    <Image
      images={[madfitScreenFourthImg, madfitScreenFourthImg2x]}
      order="fourth"
    />,
    <Image
      images={[madfitScreenFifthImg, madfitScreenFifthImg2x]}
      order="fifth"
    />,
    <Image
      images={[madfitScreenSixthImg, madfitScreenSixthImg2x]}
      order="sixth"
    />,
    <Image
      images={[madfitScreenSeventhImg, madfitScreenSeventhImg2x]}
      order="seventh"
    />,
  ]

  return (
    <section class="MadfitFeatures">
      <Container className="FeaturesContainer">
        <div ref={firstFeatureRef} class="MadfitFeatures-FirstBlock">
          <h3 class="Heading Heading_feature">
            App of
            <br />
            the day
          </h3>
          <p class="MadfitFeatures-FirstText FeatureText">
            Picked by Apple as "App of the Day" before it was even available on
            the app store.
          </p>
        </div>
        <div ref={gridRef} class="MadfitFeatures-Grid">
          {isLg ? (
            <>
              <div class="MadfitFeatures-Screens">
                {screens[0]}
                {screens[1]}
                {screens[2]}
              </div>
              <div class="MadfitFeatures-Screens">
                {screens[3]}
                {screens[4]}
                {screens[5]}
                {screens[6]}
              </div>
            </>
          ) : (
            <>
              <div class="MadfitFeatures-Screens">{screens[0]}</div>
              <div class="MadfitFeatures-Screens">
                {screens[1]}
                {screens[2]}
              </div>
              <div class="MadfitFeatures-Screens">
                {screens[3]}
                {screens[4]}
              </div>
              <div class="MadfitFeatures-Screens">
                {screens[5]}
                {screens[6]}
              </div>
            </>
          )}
        </div>
        <div class="MadfitFeatures-SecondBlock">
          <div ref={secondFeatureRef}>
            <h3 class="Heading Heading_feature">5-Star App</h3>
            <div class="MadfitFeatures-SecondText">
              <p class="FeatureText">Over 5000 5-star reviews.</p>
              <br />
              <cite class="FeatureText">
                {isXs ? (
                  `"Maddies app is awesome. I love the community aspect.
                  I have tried many of the influencer apps and this
                  is one of my favorite by far. Great job Maddie"`
                ) : (
                  <>
                    "Maddies app is awesome. I love the community aspect.
                    <br />
                    I have tried many of the influencer apps and this
                    <br />
                    is one of my favorite by far. Great job Maddie"
                  </>
                )}
              </cite>
            </div>
          </div>
          <div ref={linkRef}>
            <Link
              isBlank
              class="MadfitFeatures-Button Button"
              href={BRAND.madfit.website}
            >
              View website
            </Link>
          </div>
        </div>
      </Container>
    </section>
  )
}
