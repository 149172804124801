import strongherLogo from '../assets/images/logos/strongher.svg'
import justhatchedLogo from '../assets/images/logos/justhatched.svg'
import madfitrLogo from '../assets/images/logos/madfit.svg'
import stumblerLogo from '../assets/images/logos/stumbler.svg'
import justhatchedImg from '../assets/images/icon-justhatched.png'
import justhatchedImg2x from '../assets/images/icon-justhatched@2x.png'
import madfitImg from '../assets/images/icon-madfit.png'
import madfitImg2x from '../assets/images/icon-madfit@2x.png'
import strongherImg from '../assets/images/icon-strongher.png'
import strongherImg2x from '../assets/images/icon-strongher@2x.png'
import stumblerImg from '../assets/images/icon-stumbler.png'
import stumblerImg2x from '../assets/images/icon-stumbler@2x.png'

export const BREAKPOINT = {
  xs: 600,
  sm: 768,
  md: 960,
  lg: 1280,
}

export const QUERY_BREAKPOINT = {
  xs: `(max-width: ${BREAKPOINT.xs - 1}px)`,
  sm: `(max-width: ${BREAKPOINT.sm - 1}px)`,
  md: `(max-width: ${BREAKPOINT.md - 1}px)`,
  lg: `(min-width: ${BREAKPOINT.lg}px)`,
}

export const BRAND = {
  strongher: {
    title: 'StrongHer',
    image: strongherLogo,
    imageSize: [206, 191],
    icon: [strongherImg, strongherImg2x],
    text: 'Designed for influencer Melissa Chalmers, Vibe Media adopted an unusual approach to marketing in this situation. We gathered followers via paid acquisition and then converted them to app subscriptions. We adopted a really innovative acquisition method that increased retention while growing social profile.',
    url: 'https://apps.apple.com/app/strongher-workout-for-women/id1449792084',
    website: 'https://strongherapp.com',
  },
  madfit: {
    title: 'MadFit',
    image: madfitrLogo,
    imageSize: [156, 135],
    icon: [madfitImg, madfitImg2x],
    text: "Designed for Maddie Lymburner, one of the world's leading fitness influencers. Vibe Media developed a powerful brand that represented Maddie's brand vision.",
    url: 'https://apps.apple.com/app/madfit-workout-at-home/id1577283718',
    website: 'https://maddielymburner.co',
  },
  justhatched: {
    title: 'Just Hatched',
    image: justhatchedLogo,
    imageSize: [157, 146],
    icon: [justhatchedImg, justhatchedImg2x],
    text: 'The life of a new mum is oh-so full of questions. We’ve created Just Hatched to help you keep full track of grams and millilitres while you get to fully enjoy the precious time with your baby.',
    url: 'https://apps.apple.com/app/just-hatched-baby-tracker/id1468233687',
    website: 'https://justhatched.app',
  },
  stumbler: {
    title: 'Stumbler',
    image: stumblerLogo,
    imageSize: [162, 172],
    icon: [stumblerImg, stumblerImg2x],
    text: "Stumbler lets you discover the most amazing videos and pictures. You'll never get bored as you swipe through an endless feed of the latest trending content.",
    url: 'https://apps.apple.com/app/stumbler-hottest-vids-pics/id1546202322',
    website: 'https://www.stumbler.com',
  },
}
