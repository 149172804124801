import {h, Fragment} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './Features.scss'
import phoneImg from '../../assets/images/phone.png'
import phoneImg2x from '../../assets/images/phone@2x.png'
import phoneTabletImg from '../../assets/images/phone_tablet.png'
import phoneTabletImg2x from '../../assets/images/phone_tablet@2x.png'
import phoneMobileImg from '../../assets/images/phone_mobile.png'
import phoneMobileImg2x from '../../assets/images/phone_mobile@2x.png'
import linesImg from '../../assets/images/lines.png'
import linesImg2x from '../../assets/images/lines@2x.png'
import linesTabletImg from '../../assets/images/lines_tablet.png'
import linesTabletImg2x from '../../assets/images/lines_tablet@2x.png'
import linesMobileImg from '../../assets/images/lines_mobile.png'
import linesMobileImg2x from '../../assets/images/lines_mobile@2x.png'
import {Container} from '../common'
import {DevelopIcon, MoneyIcon, ChartIcon} from '../icons'
import {QUERY_BREAKPOINT} from '../../constants'

const features = [
  {
    Icon: DevelopIcon,
    text: (
      <>
        High End
        <br />
        Application
        <br />
        Development
      </>
    ),
  },
  {
    Icon: MoneyIcon,
    text: (
      <>
        Crafting brands
        <br />
        for influencers, and
        <br />
        audience monetization
      </>
    ),
  },
  {
    Icon: ChartIcon,
    text: (
      <>
        Marketing, Conversion,
        <br />
        and Data Science
        <br />
        Specialization
      </>
    ),
  },
]

export default function Features() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const wrapperRef = useRef(null)
  const imageRef = useRef(null)
  const linesRef = useRef(null)
  const titleRef = useRef(null)
  const listRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.from(wrapperRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: wrapperRef.current,
          scrub: 0.5,
        },
        opacity: 0.5,
        scale: 0.85,
        y: 100,
      })
      gsap.from(titleRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: titleRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        y: 50,
      })
      gsap.utils
        .selector(listRef.current)('.Features-Item')
        .forEach((item) =>
          gsap.from(item, {
            scrollTrigger: {
              start: 'center bottom',
              trigger: item,
              scrub: 0.5,
            },
            opacity: 0,
            y: 50,
          })
        )
      return
    }

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: 'center center',
        scrub: 0.5,
        pin: true,
      },
    })
    tl.from(imageRef.current, {
      y: 100,
      scale: 0.9,
    }).from(linesRef.current, {
      opacity: 0,
      skewY: 60,
      scaleY: 0.3,
    })
    gsap.from(titleRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: titleRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      y: 50,
    })
    gsap.from(gsap.utils.selector(listRef.current)('.Features-Item'), {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: listRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      y: 50,
      stagger: 0.1,
    })
  }, [])

  return (
    <section class="Features">
      <Container type="grid">
        <div ref={wrapperRef} class="Features-Wrapper">
          <picture>
            <source
              width={310}
              height={586}
              media={QUERY_BREAKPOINT.xs}
              srcSet={`${phoneMobileImg}, ${phoneMobileImg2x} 2x`}
            />
            <source
              width={501}
              height={950}
              media={QUERY_BREAKPOINT.sm}
              srcSet={`${phoneTabletImg}, ${phoneTabletImg2x} 2x`}
            />
            <img
              ref={imageRef}
              class="Features-Image"
              width={716}
              height={1358}
              src={phoneImg}
              srcSet={`${phoneImg2x} 2x`}
              alt="Phone"
            />
          </picture>
          <picture>
            <source
              width={229}
              height={505}
              media={QUERY_BREAKPOINT.xs}
              srcSet={`${linesMobileImg}, ${linesMobileImg2x} 2x`}
            />
            <source
              width={373}
              height={824}
              media={QUERY_BREAKPOINT.sm}
              srcSet={`${linesTabletImg}, ${linesTabletImg2x} 2x`}
            />
            <img
              ref={linesRef}
              class="Features-ImageDecoration"
              width={530}
              height={1176}
              src={linesImg}
              srcSet={`${linesImg2x} 2x`}
              alt="Lines"
            />
          </picture>
        </div>
        <h2 ref={titleRef} class="Features-Title">
          – Apps and brands that work for you.
        </h2>
        <ul ref={listRef} class="Features-List">
          {features.map(({Icon, text}, index) => (
            <li key={index} class="Features-Item">
              <Icon class="Features-Icon" />
              <p>{text}</p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
