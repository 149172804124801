import {h} from 'preact'

import './Footer.scss'
import {Container, Logo, Link} from '../common'

const email = 'team@vibemedia.app'

export default function Footer() {
  return (
    <footer class="Footer">
      <Container>
        <Logo isFooter />
        <p class="Footer-Title">Become our client</p>
        <p class="Footer-Text">
          Contact our customer support if you have any questions.
          <br />
          We’ll help you out:{' '}
          <Link isBlank class="Footer-Email" href={`mailto:${email}`}>
            {email}
          </Link>
        </p>
        <p>
          <Link isBlank class="Footer-Link" href="/terms.html">
            Terms & Conditions
          </Link>
          <span class="Footer-Separator">|</span>
          <Link isBlank class="Footer-Link" href="/privacy.html">
            Privacy Policy
          </Link>
        </p>
      </Container>
    </footer>
  )
}
