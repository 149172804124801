import {h} from 'preact'

export default function DevelopIcon(props) {
  return (
    <svg width={40} height={40} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#F26041"
        strokeWidth={3}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21.9 13.32l-3.8 13.34M13.808 21.896L10 18.086l3.808-3.808M26.192 25.706L30 21.896l-3.808-3.808" />
        <path d="M12 1.991h16c5.523 0 10 4.477 10 10v16c0 5.523-4.477 10-10 10H12c-5.523 0-10-4.477-10-10v-16c0-5.523 4.477-10 10-10z" />
      </g>
    </svg>
  )
}
