import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import {Link} from 'preact-router/match'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

import './Header.scss'
import headerVideo from '../../assets/videos/header.mp4'
import headerSmallVideo from '../../assets/videos/header_small.mp4'
import {Container, Logo} from '../common'
import {QUERY_BREAKPOINT} from '../../constants'

const scrollDuration = 1500

const getWordSpanLetters = (word) =>
  word.split('').map((letter, index) => <span key={index}>{letter}</span>)

const HeaderVideo = () => {
  return (
    <video autoplay muted loop playsInline class="Header-Background">
      <source src={headerVideo} type="video/mp4" />
    </video>
  )
}

const HeaderSmallVideo = () => {
  return (
    <video autoplay muted loop playsInline class="Header-Background">
      <source src={headerSmallVideo} type="video/mp4" />
    </video>
  )
}

export default function Header() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const firstTextRef = useRef(null)
  const secondTextRef = useRef(null)
  const listRef = useRef(null)
  const timerRef = useRef(null)

  useEffect(() => {
    gsap.from(gsap.utils.selector(firstTextRef.current)('span'), {
      scale: 1.5,
      opacity: 0,
      stagger: 0.08,
      y: -5,
      filter: 'blur(10px)',
    })
    gsap.from(gsap.utils.selector(secondTextRef.current)('span'), {
      scale: 1.5,
      opacity: 0,
      stagger: 0.06,
      y: 5,
      delay: 2.3,
      filter: 'blur(10px)',
    })
    gsap.from(gsap.utils.selector(listRef.current)('li'), {
      filter: 'blur(20px)',
      scale: 2,
      stagger: 0.1,
    })
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const handleClick = () => {
    const scrollTriggers = ScrollTrigger.getAll()
    scrollTriggers.forEach((trigger) => trigger.disable(false))
    timerRef.current = setTimeout(() => {
      scrollTriggers.forEach((trigger) => trigger.enable())
    }, scrollDuration)
    gsap.to(window, {
      duration: scrollDuration / 1000,
      scrollTo: {y: '#contact', offsetY: -50},
    })
  }

  return (
    <header class="Header">
      {isSm ? <HeaderSmallVideo /> : <HeaderVideo />}
      <Container type="grid" contentClass="Header-Content">
        <div class="Header-Panel">
          <Logo />
          <nav>
            <ul ref={listRef} class="Header-List">
              <li class="Header-Item">
                <Link class="Link" href="/team">
                  Team
                </Link>
              </li>
              <li class="Header-Item">
                <button class="Link" onClick={handleClick}>
                  Contact us
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="Header-Title">
          <p ref={firstTextRef} class="Header-Text">
            {getWordSpanLetters('Wearing')} <span>a</span>{' '}
            {getWordSpanLetters('brand')}
            <br />
            {getWordSpanLetters('is')} {getWordSpanLetters('not')}{' '}
            {getWordSpanLetters('the')} {getWordSpanLetters('goal.')}
          </p>
          <p ref={secondTextRef} class="Header-Text Header-Sentence">
            {getWordSpanLetters('Becoming')} {getWordSpanLetters('one')}{' '}
            {getWordSpanLetters('is.')}
          </p>
        </div>
      </Container>
    </header>
  )
}
