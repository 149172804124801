import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './CaseStudies.scss'
import {Container} from '../common'
import {QUERY_BREAKPOINT, BRAND} from '../../constants'

const brands = Object.values(BRAND)

export default function CaseStudies() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const listRef = useRef(null)
  const firstTextRef = useRef(null)
  const secondTextRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.utils
        .selector(listRef.current)('.CaseStudies-Item')
        .forEach((item) =>
          gsap.from(item, {
            scrollTrigger: {
              start: 'center bottom',
              trigger: item,
              scrub: 0.5,
            },
            opacity: 0,
            scale: 0.8,
          })
        )
      gsap.from(firstTextRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: firstTextRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        x: 30,
      })
      gsap.from(secondTextRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: secondTextRef.current,
          scrub: 0.5,
        },
        x: 30,
        opacity: 0,
      })
      return
    }

    gsap.from(gsap.utils.selector(listRef.current)('.CaseStudies-Item'), {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: listRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      scale: 0.8,
      x: 5,
      y: 5,
      stagger: 0.1,
    })
    gsap.from(firstTextRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: firstTextRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      y: 50,
    })
    gsap.from(secondTextRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: secondTextRef.current,
        scrub: 0.5,
      },
      x: 50,
      opacity: 0,
      delay: 0.2,
    })
  }, [])

  return (
    <Container element="section" className="CaseStudies" type="small">
      <h2 class="CaseStudies-Title">Case studies</h2>
      <ul ref={listRef} class="CaseStudies-List">
        {brands.map((brand) => (
          <li class="CaseStudies-Item">
            <img
              class="CaseStudies-Image"
              width={185}
              height={185}
              src={brand.icon[0]}
              srcSet={`${brand.icon[1]} 2x`}
              alt={brand.title}
            />
          </li>
        ))}
      </ul>
      <div class="CaseStudies-Block">
        <p ref={firstTextRef} class="CaseStudies-Text Title">
          100% success rate with every brand
        </p>
        <p ref={secondTextRef}>
          Not only do we know how to create some of the coolest-looking brands,
          but we also understand full-spectrum development. From the concept to
          the brand book. From the brand book to the App Launch. From the app
          launch through data analysis; and from data analysis to marketing.
        </p>
      </div>
    </Container>
  )
}
