import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './StumblerAbout.scss'
import stumblerScreensImg from '../../assets/images/stumbler/stumbler-screens.png'
import stumblerScreensImg2x from '../../assets/images/stumbler/stumbler-screens@2x.png'
import stumblerScreensTabletImg from '../../assets/images/stumbler/stumbler-screens_tablet.png'
import stumblerScreensTabletImg2x from '../../assets/images/stumbler/stumbler-screens_tablet@2x.png'
import stumblerScreensMobileImg from '../../assets/images/stumbler/stumbler-screens_mobile.png'
import stumblerScreensMobileImg2x from '../../assets/images/stumbler/stumbler-screens_mobile@2x.png'
import {Container, BrandDescription} from '../common'
import {QUERY_BREAKPOINT, BRAND} from '../../constants'

export default function StumblerAbout() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const imageRef = useRef(null)
  const descriptionRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.from(imageRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: imageRef.current,
          scrub: 0.5,
        },
        scale: 1.5,
        opacity: 0.2,
      })
      gsap.from(descriptionRef.current, {
        scrollTrigger: {
          start: 'top bottom',
          trigger: descriptionRef.current,
          scrub: 0.5,
        },
        y: 50,
        opacity: 0,
      })
      return
    }

    gsap.from(imageRef.current, {
      scrollTrigger: {
        start: 'center bottom',
        trigger: imageRef.current,
        scrub: 0.5,
      },
      opacity: 0.2,
    })
    gsap.from(descriptionRef.current, {
      scrollTrigger: {
        start: 'center bottom',
        trigger: descriptionRef.current,
        scrub: 0.5,
      },
      y: 50,
      opacity: 0,
    })
  }, [])

  return (
    <section class="StumblerAbout">
      <Container type="small" contentClass="StumblerAbout-Content AboutContent">
        <div ref={descriptionRef}>
          <BrandDescription type="stumbler" />
        </div>
        <picture>
          <source
            width={321}
            height={402}
            media={QUERY_BREAKPOINT.xs}
            srcSet={`${stumblerScreensMobileImg}, ${stumblerScreensMobileImg2x} 2x`}
          />
          <source
            width={461}
            height={579}
            media={QUERY_BREAKPOINT.md}
            srcSet={`${stumblerScreensTabletImg}, ${stumblerScreensTabletImg2x} 2x`}
          />
          <img
            ref={imageRef}
            class="StumblerAbout-Image"
            width={661}
            height={832}
            src={stumblerScreensImg}
            srcSet={`${stumblerScreensImg2x} 2x`}
            alt={`${BRAND.stumbler.title} examples`}
          />
        </picture>
      </Container>
    </section>
  )
}
