import {h, Fragment} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './Stats.scss'
import {Container} from '../common'
import {QUERY_BREAKPOINT} from '../../constants'

const stats = [
  {
    value: '10,000,000+',
    label: (
      <>
        Number of combined
        <br />
        installs through portfolio
      </>
    ),
  },
  {
    value: '395,000 +',
    label: (
      <>
        Active users
        <br />
        every month
      </>
    ),
  },
  {
    value: '$23,000,000',
    label: (
      <>
        Recurring subscription revenue
        <br />
        generated through our
        <br />
        portfolio and clients' brands
      </>
    ),
  },
]

export default function Stats() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const titleRef = useRef(null)
  const listRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.from(titleRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: titleRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        x: 50,
      })
      gsap.utils
        .selector(listRef.current)('.Stats-Item')
        .forEach((item) =>
          gsap.from(item, {
            scrollTrigger: {
              start: 'center bottom',
              trigger: item,
              scrub: 0.5,
            },
            opacity: 0,
            x: -30,
          })
        )
      return
    }

    gsap.from(titleRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: titleRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      x: 50,
    })
    gsap.from(gsap.utils.selector(listRef.current)('.Stats-Item'), {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: listRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      x: -30,
      stagger: 0.1,
    })
  }, [])

  return (
    <section class="Stats">
      <Container type="grid" contentClass="Stats-Content">
        <h2 ref={titleRef} class="Stats-Title Title">
          Numbers we
          <br />
          are proud of
        </h2>
        <ul ref={listRef} class="Stats-List">
          {stats.map(({value, label}, index) => (
            <li key={index} class="Stats-Item">
              <p class="Stats-Value">{value}</p>
              <p class="Stats-Labrl">{label}</p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
