import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import {useMediaQuery} from 'react-responsive'
import gsap from 'gsap'

import './Services.scss'
import {Container} from '../common'
import {QUERY_BREAKPOINT} from '../../constants'

const services = [
  {
    title: 'Brand Book',
    text: 'Our goal is to make your brand stand out from the crowd. We create a one-of-a-kind brand identity using carefully formulated brand strategies that is relatable to your target audience',
    type: 'brand',
  },
  {
    title: 'Website',
    text: "As a brand's first and most crucial point of contact with its target audience, we believe that your website should deliver a powerful first impression. We design user-friendly, responsive websites that captivate your audience and draw them closer to you",
    type: 'website',
  },
  {
    title: 'Application',
    text: 'Transform your vision into interactive mobile apps for Apple and Android devices. Discuss your goals with our technical team, and create storyboard concepts to build user-friendly, human-centered designs that users love',
    type: 'app',
  },
  {
    title: 'Marketing',
    text: 'We study your customers, competitors, and the market as a whole. And based on reliable data, we outline the best marketing strategies that help you connect with your customers in meaningful and successful ways',
    type: 'marketing',
  },
  {
    title: 'Analytics',
    text: "We recognize the value of data analysis in a business and put it to good use. Our data science team employs data and analytics to monitor and enhance your app's performance and explore methods that generate results",
    type: 'analytics',
  },
]

export default function Services() {
  const isSm = useMediaQuery({query: QUERY_BREAKPOINT.sm})
  const textRef = useRef(null)
  const listRef = useRef(null)

  useEffect(() => {
    if (isSm) {
      gsap.from(textRef.current, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: textRef.current,
          scrub: 0.5,
        },
        opacity: 0,
        y: 50,
      })
      gsap.utils
        .selector(listRef.current)('.Services-Item')
        .forEach((item) =>
          gsap.from(item, {
            scrollTrigger: {
              start: 'top bottom',
              trigger: item,
              scrub: 0.5,
            },
            opacity: 0,
            y: 50,
            scale: 0.9,
          })
        )
      return
    }

    gsap.from(textRef.current, {
      scrollTrigger: {
        start: 'bottom bottom',
        trigger: textRef.current,
        scrub: 0.5,
      },
      opacity: 0,
      y: 50,
    })
    const items = gsap.utils.selector(listRef.current)('.Services-Item')
    items.forEach((item, index) =>
      gsap.from(item, {
        scrollTrigger: {
          start: 'center bottom',
          trigger: item,
          scrub: 0.5,
        },
        opacity: 0,
        ...(items.length - 1 === index ? {y: 50} : {x: index % 2 ? 50 : -50}),
      })
    )
  }, [])

  return (
    <Container element="section" className="Services" type="small">
      <h2 class="Services-Title">Services</h2>
      <p ref={textRef} class="Services-Text">
        What we offer.
      </p>
      <ul ref={listRef} class="Services-List">
        {services.map(({title, text, type}, index) => (
          <li key={index} class={`Services-Item Services-Item_type_${type}`}>
            <h3 class="Services-Subtitle">{title}</h3>
            <p class="Services-Description">{text}.</p>
          </li>
        ))}
      </ul>
    </Container>
  )
}
