import {h} from 'preact'

export default function MoneyIcon(props) {
  return (
    <svg width={40} height={40} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="#FD9A9D"
        strokeWidth={3}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M23 25a7 7 0 110-14 7 7 0 010 14" />
        <path d="M38 10v20a8 8 0 01-8 8H10a8 8 0 01-8-8V10a8 8 0 018-8h20a8 8 0 018 8z" />
        <path d="M16.66 15.036C12.956 15.216 10 18.252 10 22a7 7 0 007 7c2.794 0 5.186-1.648 6.31-4.016" />
      </g>
    </svg>
  )
}
