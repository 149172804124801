import {h} from 'preact'
import {useEffect, useRef} from 'preact/hooks'
import gsap from 'gsap'

import './Team.scss'
import billImg from '../../assets/images/team/bill.png'
import billImg2x from '../../assets/images/team/bill@2x.png'
import hazarImg from '../../assets/images/team/hazar.png'
import hazarImg2x from '../../assets/images/team/hazar@2x.png'
import igorImg from '../../assets/images/team/igor.png'
import igorImg2x from '../../assets/images/team/igor@2x.png'
import maksimImg from '../../assets/images/team/maksim.png'
import maksimImg2x from '../../assets/images/team/maksim@2x.png'
import leeImg from '../../assets/images/team/lee.png'
import leeImg2x from '../../assets/images/team/lee@2x.png'
import pamImg from '../../assets/images/team/pam.png'
import pamImg2x from '../../assets/images/team/pam@2x.png'
import sahaImg from '../../assets/images/team/saha.png'
import sahaImg2x from '../../assets/images/team/saha@2x.png'
import liviImg from '../../assets/images/team/livi.png'
import liviImg2x from '../../assets/images/team/livi@2x.png'
import parasImg from '../../assets/images/team/paras.png'
import parasImg2x from '../../assets/images/team/paras@2x.png'
import aliImg from '../../assets/images/team/ali.png'
import aliImg2x from '../../assets/images/team/ali@2x.png'
import shahbazImg from '../../assets/images/team/shahbaz.png'
import shahbazImg2x from '../../assets/images/team/shahbaz@2x.png'
import jennyImg from '../../assets/images/team/jenny.jpg'
import jennyImg2x from '../../assets/images/team/jenny@2x.jpg'
import kateImg from '../../assets/images/team/kate.jpg'
import kateImg2x from '../../assets/images/team/kate@2x.jpg'
import rubyImg from '../../assets/images/team/ruby.jpg'
import rubyImg2x from '../../assets/images/team/ruby@2x.jpg'
import russellImg from '../../assets/images/team/russell.jpg'
import russellImg2x from '../../assets/images/team/russell@2x.jpg'
import vladpImg from '../../assets/images/team/vladp.jpg'
import vladpImg2x from '../../assets/images/team/vladp@2x.jpg'
import vladtImg from '../../assets/images/team/vladt.jpg'
import vladtImg2x from '../../assets/images/team/vladt@2x.jpg'
import andrewImg from '../../assets/images/team/andrew.jpg'
import andrewImg2x from '../../assets/images/team/andrew@2x.jpg'
import antonImg from '../../assets/images/team/anton.jpg'
import antonImg2x from '../../assets/images/team/anton@2x.jpg'
import denisImg from '../../assets/images/team/denis.jpg'
import denisImg2x from '../../assets/images/team/denis@2x.jpg'
import elvaImg from '../../assets/images/team/elva.jpg'
import elvaImg2x from '../../assets/images/team/elva@2x.jpg'
import michaelImg from '../../assets/images/team/michael.jpg'
import michaelImg2x from '../../assets/images/team/michael@2x.jpg'

import {Container, Logo} from '../common'

const leadPersons = [
  {
    name: 'Bill Chalmers',
    photo: [billImg, billImg2x],
    occupation: 'Chief Executive Officer',
  },

  {
    name: 'Hazar Akdag',
    photo: [hazarImg, hazarImg2x],
    occupation: 'Executive Advisor',
  },
  {
    name: 'Igor Anany',
    photo: [igorImg, igorImg2x],
    occupation: 'Chief Technical Officer',
  },
  {
    name: 'Lee Smith',
    photo: [leeImg, leeImg2x],
    occupation: 'Chief Operating Officer',
  },
  {
    name: 'Saha Anany',
    photo: [sahaImg, sahaImg2x],
    occupation: 'Chief Design Officer',
  },
  {
    name: 'Max Kreidich',
    photo: [maksimImg, maksimImg2x],
    occupation: 'Chief Marketing Officer',
  },
  {
    name: 'Pam Dizon',
    photo: [pamImg, pamImg2x],
    occupation: 'Chief Administrative Officer',
  },
  {
    name: 'Livi Baker',
    photo: [liviImg, liviImg2x],
    occupation: 'Social Media Content Specialist',
  },
  {
    name: 'Andrew Novikov',
    photo: [andrewImg, andrewImg2x],
    occupation: 'Software Engineering Specialist',
  },
  {
    name: 'Paras Kuhad',
    photo: [parasImg, parasImg2x],
    occupation: 'Software Engineering Specialist',
  },
  {
    name: 'Michael Ananyev',
    photo: [michaelImg, michaelImg2x],
    occupation: 'Senior Frontend Engineer',
  },
  {
    name: 'Anton Gubarenko',
    photo: [antonImg, antonImg2x],
    occupation: 'Senior Frontend Engineer',
  },
  {
    name: 'Denis Doroshenko',
    photo: [denisImg, denisImg2x],
    occupation: 'Senior Frontend Engineer',
  },
  {
    name: 'Ali Asadullah',
    photo: [aliImg, aliImg2x],
    occupation: 'Senior Frontend Engineer',
  },
  {
    name: 'Shahbaz Ali',
    photo: [shahbazImg, shahbazImg2x],
    occupation: 'Content Specialist',
  },
  {
    name: 'Jenny Rozsa',
    photo: [jennyImg, jennyImg2x],
    occupation: 'Customer Success Manager',
  },
  {
    name: 'Kate Stasiuk',
    photo: [kateImg, kateImg2x],
    occupation: 'Senior Data Analyst',
  },
  {
    name: 'Ruby Malloy',
    photo: [rubyImg, rubyImg2x],
    occupation: 'Motion Graphics Design',
  },
  {
    name: 'Russell Goodman',
    photo: [russellImg, russellImg2x],
    occupation: 'Direct Response Marketing',
  },
  {
    name: 'Elva Park',
    photo: [elvaImg, elvaImg2x],
    occupation: 'Graphic Designer',
  },
  {
    name: 'Vlad Polikashkin',
    photo: [vladpImg, vladpImg2x],
    occupation: 'Marketing Manager',
  },
  {
    name: 'Vlad Tuichev',
    photo: [vladtImg, vladtImg2x],
    occupation: 'Software Engineering Specialist',
  },
]

const persons = []

const Person = ({small, name, occupation, photo}) => {
  const imgSize = small ? 214 : 327
  return (
    <li class="Team-Item">
      <img
        class="Team-Photo"
        width={imgSize}
        height={imgSize}
        src={photo[0]}
        srcSet={`${photo[1]} 2x`}
        alt={name}
      />
      <p class="Team-Name">{name}</p>
      <p class="Team-Occupation">{occupation}</p>
    </li>
  )
}

export default function Team() {
  const titleRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    gsap.from(titleRef.current, {
      x: 50,
      opacity: 0,
    })
    gsap.from(textRef.current, {
      y: 30,
      opacity: 0,
      delay: 0.2,
    })
  }, [])

  return (
    <div class="Team">
      <Container
        type="grid"
        class="FadeIn FadeIn_longer"
        contentClass="Team-Content"
      >
        <header class="Team-Header">
          <Logo />
        </header>
        <main>
          <section>
            <div class="Team-Breadcrumbs">
              <a class="Team-Link Link" href="/">
                Home
              </a>
              <span class="Team-BreadcrumbsSeparator">/</span>
              <span class="Team-Link Team-Link_active Link">Team</span>
            </div>
            <h1 ref={titleRef} class="Team-Title Title">
              We are passionate
            </h1>
            <p ref={textRef} class="Team-Text">
              A boutique team that prioritizes quality over quantity. Our team
              is on a mission to transform the fitness app industry for
              handpicked influencers.
            </p>
            <ul class="Team-List">
              {leadPersons.map((person) => (
                <Person key={person.name} {...person} />
              ))}
            </ul>
            <ul class="Team-Sublist">
              {persons.map((person) => (
                <Person key={person.name} small {...person} />
              ))}
            </ul>
          </section>
        </main>
      </Container>
    </div>
  )
}
