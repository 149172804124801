import {h} from 'preact'
import {useRef, useEffect, useState} from 'preact/hooks'
import cn from 'clsx'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import gsap from 'gsap'

import './StumblerFeatures.scss'
import stumblerPhoneImg from '../../assets/images/stumbler/stumbler-phone.png'
import stumblerPhoneImg2x from '../../assets/images/stumbler/stumbler-phone@2x.png'
import firstVideo from '../../assets/videos/stumbler-first.mp4'
import secondVideo from '../../assets/videos/stumbler-second.mp4'
import thirdVideo from '../../assets/videos/stumbler-third.mp4'
import fourthVideo from '../../assets/videos/stumbler-fourth.mp4'
import fifthVideo from '../../assets/videos/stumbler-fifth.mp4'
import sixthVideo from '../../assets/videos/stumbler-sixth.mp4'
import seventhVideo from '../../assets/videos/stumbler-seventh.mp4'
import {Container} from '../common'
import {QUERY_BREAKPOINT, BREAKPOINT} from '../../constants'

const videos = [
  firstVideo,
  secondVideo,
  thirdVideo,
  fourthVideo,
  fifthVideo,
  sixthVideo,
  seventhVideo,
]

export default function StumblerFeatures() {
  const [videoIndex, setVideoIndex] = useState(0)
  const phoneRef = useRef(null)
  const featureRef = useRef(null)
  const firstVideoRef = useRef(null)
  const secondVideoRef = useRef(null)
  const thirdVideoRef = useRef(null)
  const fourthVideoRef = useRef(null)
  const fifthVideoRef = useRef(null)
  const sixthVideoRef = useRef(null)
  const seventhVideoRef = useRef(null)
  const timerRef = useRef(null)

  useEffect(() => {
    ScrollTrigger.matchMedia({
      [QUERY_BREAKPOINT.lg]: () => {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: phoneRef.current,
            start: 'center center',
            scrub: 0.5,
            pin: true,
          },
        })
        tl.from(featureRef.current, {
          opacity: 0,
        }).to(featureRef.current, {opacity: 0})
      },
      [`(max-width: ${BREAKPOINT.lg - 1}px)`]: () => {
        gsap.from(featureRef.current, {
          scrollTrigger: {
            start: 'center bottom',
            trigger: featureRef.current,
            scrub: 0.5,
          },
          opacity: 0,
          y: 50,
        })
      },
    })
  }, [])

  const videoRefs = [
    firstVideoRef,
    secondVideoRef,
    thirdVideoRef,
    fourthVideoRef,
    fifthVideoRef,
    sixthVideoRef,
    seventhVideoRef,
  ]

  useEffect(() => {
    const currentVideo = videoRefs[videoIndex].current
    currentVideo.playbackRate = 1.75
    currentVideo.play()
    timerRef.current = setTimeout(changeVideo, 3000)
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [videoIndex])

  const changeVideo = () => {
    const currentVideo = videoRefs[videoIndex].current
    if (currentVideo) {
      currentVideo.pause()
      currentVideo.currentTime = 0
      setVideoIndex(videoIndex === videos.length - 1 ? 0 : videoIndex + 1)
    }
  }

  return (
    <section class="StumblerFeatures">
      <Container
        type="grid"
        className="FeaturesContainer"
        contentClass="StumblerFeatures-Content"
      >
        <div ref={phoneRef} class="StumblerFeatures-Phone">
          <div class="StumblerFeatures-PhoneContent" />
          {videos.map((video, index) => (
            <video
              key={index}
              ref={videoRefs[index]}
              muted
              loop
              playsInline
              class={cn(
                'StumblerFeatures-PhoneContent StumblerFeatures-Video',
                index === videoIndex && 'StumblerFeatures-Video_show'
              )}
            >
              <source src={video} type="video/mp4" />
            </video>
          ))}
          <picture>
            <source
              width={300}
              height={614}
              media={QUERY_BREAKPOINT.xs}
              srcSet={`${stumblerPhoneImg}, ${stumblerPhoneImg2x} 2x`}
            />
            <source
              width={460}
              height={895}
              media={QUERY_BREAKPOINT.md}
              srcSet={`${stumblerPhoneImg}, ${stumblerPhoneImg2x} 2x`}
            />
            <img
              class="StumblerFeatures-Image"
              width={654}
              height={1271}
              src={stumblerPhoneImg}
              srcSet={`${stumblerPhoneImg2x} 2x`}
              alt="Phone"
            />
          </picture>
        </div>
        <div ref={featureRef} class="StumblerFeatures-Block">
          <h3 class="StumblerFeatures-Heading Heading Heading_feature">
            1.3b video views
          </h3>
          <p class="FeatureText">
            Fastest growing entertainment app of 2021:
            <br />
            1,300,000,000+ videos views,
            <br />
            1,300,000+ downloads,
            <br />
            40,000+ daily users.
            <br />
            <br />
            Highly engaged community.
          </p>
        </div>
      </Container>
    </section>
  )
}
