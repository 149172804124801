import {h} from 'preact'
import PropTypes from 'prop-types'
import cn from 'clsx'

import './Container.scss'

Container.propTypes = {
  element: PropTypes.string,
  type: PropTypes.oneOf(['grid', 'small']),
  className: PropTypes.string,
  contentClass: PropTypes.string,
  children: PropTypes.any.isRequired,
}

export default function Container({
  element: Element = 'div',
  type,
  className,
  contentClass,
  children,
}) {
  return (
    <Element class={cn(className, 'Container', type && `Container_${type}`)}>
      {type === 'grid' || contentClass ? (
        <div class={contentClass}>{children}</div>
      ) : (
        children
      )}
    </Element>
  )
}
